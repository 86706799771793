import {
  ConversationsMatchCountResponseModel,
  conversationsAPI,
} from '@capturi/api-conversations'
import { useAPI } from '@capturi/api-utils'
import { SWRConfiguration } from 'swr'

import { useFirstPhoneSegmentState } from '../state/segment-state'

export function useConversationMatchCount(
  swrConfig: SWRConfiguration,
): number | undefined {
  const segmentState = useFirstPhoneSegmentState()
  const { data } = useAPI<ConversationsMatchCountResponseModel>(
    conversationsAPI.getConversationsMatchCount(),
    segmentState.getFilterRequestModel,
    swrConfig,
  )
  return data?.conversations
}
