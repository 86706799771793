import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import { useConversationMatchCount } from '../../hooks/useConversationMatchCount'

export const ConversationsMatchCount: React.FC = () => {
  const count = useConversationMatchCount({ suspense: false })
  return (
    <Text fontSize="sm">
      <Trans>Conversations: {count ?? ''}</Trans>
    </Text>
  )
}
