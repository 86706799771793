import request, { ResponseError } from '@capturi/request'
import {
  InfiniteData,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query'

import { BaseConversation, ConversationsResponseModel } from '../models'

type randomConversationOptions = {
  filter: Record<string, unknown>
  numberOfRandomConversations: number
  isEnabled: boolean
}

const selectConversations = (
  data: InfiniteData<ConversationsResponseModel>,
): InfiniteData<BaseConversation[]> => ({
  pages: data.pages.map((page) => page.conversations),
  pageParams: [],
})

const getNextPageParam = () => null

//To make the cache model the same for getConversations and getRandomConversations we use InfiniteQuery
// even though there never are more than one page
export const useRandomConversations = ({
  filter,
  isEnabled,
  numberOfRandomConversations,
}: randomConversationOptions): UseInfiniteQueryResult<
  InfiniteData<BaseConversation[]>,
  ResponseError
> =>
  useInfiniteQuery({
    queryKey: [
      'conversations',
      'list',
      'randomizer',
      filter,
      numberOfRandomConversations,
    ],
    queryFn: async () => {
      const result = await request.post<{ conversations: BaseConversation[] }>(
        'conversations/qa?api-version=3.3',
        {
          json: {
            ...filter,
            pageSize: numberOfRandomConversations,
          },
        },
      )
      return { conversations: result.conversations, continuationToken: null }
    },
    select: selectConversations,
    getNextPageParam,
    initialPageParam: null,
    staleTime: Number.POSITIVE_INFINITY,
    enabled: isEnabled,
  })
