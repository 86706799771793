import { useRandomConversations } from '@capturi/api-conversations'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { useCurrentUser } from '@capturi/core'
import {
  ConversationsMatchCount,
  FilterPeriodSelectContainer,
  PhoneSegmentBuilderState,
  SingleSegmentContainer,
  TextSegmentBuilderState,
  useFilterDefinitions,
  useFirstPhoneSegmentState,
  useSegmentStatesContext,
} from '@capturi/filters'
import {
  ErrorBoundary,
  usePageTitle,
  useScrollable,
} from '@capturi/react-utils'
import { ContentPlaceholder, PageHeading } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box, Divider, Flex, Grid, GridItem, HStack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { startTransition, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import ConversationDetails from '../../../components/ConversationDetails'
import casesRoutes from '../../Cases/routes'
import routes, { ConversationPageView } from '../routes'
import ConversationsTable from './ConversationsTable'
import StartRandomizerModal from './StartRandomizerModal'

const NoConversationFound: React.FC = () => (
  <ContentPlaceholder.Container mt={8}>
    <ContentPlaceholder.Image as={Icon_EmptyState} />
    <ContentPlaceholder.Heading>
      <Trans>No conversation selected</Trans>
    </ContentPlaceholder.Heading>
    <ContentPlaceholder.Body>
      <Trans>
        Choose a conversation from the conversation list to the left
      </Trans>
    </ContentPlaceholder.Body>
  </ContentPlaceholder.Container>
)

const ConversationsPage: React.FC = () => {
  usePageTitle(t`Conversations`)
  const { uid: conversationUidParam } = useParams()

  const { states: allSegmentStates } = useSegmentStatesContext()
  const [numberOfRandomConversations, setNumberOfRandomConversations] =
    useState<number>(20)
  const [isRandomizerEnabled, setIsEnabledRandomizer] = useState<boolean>(false)

  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  const [selectedConversationUid, setSelectedConversationUid] = useState<
    string | undefined
  >(conversationUidParam)

  // If we click on a conversation link while having the conversation page open (notifications)
  // we need to listen to url changes
  if (
    conversationUidParam &&
    conversationUidParam !== selectedConversationUid
  ) {
    setSelectedConversationUid(conversationUidParam)
  }

  const ref = React.useRef<HTMLDivElement | null>(null)
  const height = useScrollable(ref)

  const navigate = useNavigate()

  useEffectOnce(() => {
    if (
      allSegmentStates.length > 0 &&
      allSegmentStates[0].channel === 'email'
    ) {
      navigate(casesRoutes.list())
    }
  })

  const [openRandomizer] = useModal(StartRandomizerModal)

  const segmentState = useFirstPhoneSegmentState()

  const randomData = useRandomConversations({
    filter: segmentState.getFilterRequestModel(),
    isEnabled: isRandomizerEnabled,
    numberOfRandomConversations: numberOfRandomConversations,
  })

  const handleRefetchRandomConversations = React.useCallback(() => {
    // We set the uid to undefined, so the top result is selected
    setSelectedConversationUid(undefined)
    randomData.refetch()
  }, [randomData])

  const handleConversationTabOpen = React.useCallback(
    (conversationTab: ConversationPageView): void => {
      if (!selectedConversationUid) {
        return
      }
      startTransition(() => {
        navigate(
          routes.conversation(selectedConversationUid, conversationTab),
          {
            replace: true,
          },
        )
      })
    },
    [navigate, selectedConversationUid],
  )

  const handleNavigateToConversation = React.useCallback(
    (uid: string, tabName?: ConversationPageView): void => {
      setSelectedConversationUid(uid)
      startTransition(() => {
        navigate(routes.conversation(uid, tabName))
      })
    },
    [navigate],
  )

  const handleSegmentStateChange = React.useCallback(
    (state: PhoneSegmentBuilderState | TextSegmentBuilderState) => {
      if (state.channel === 'email') {
        navigate(casesRoutes.list())
      } else {
        setSelectedConversationUid(undefined)
      }
    },
    [navigate],
  )

  return (
    <Flex
      className="conversations-container"
      flexDir="column"
      h={height}
      overflowY="hidden"
      ref={ref}
    >
      <Box mb={4} flex="0 1 auto">
        <Flex align="center" justify="space-between" mb={8}>
          <PageHeading>
            <Trans>All conversations</Trans>
          </PageHeading>
          <HStack>
            <ConversationsMatchCount />
            <FilterPeriodSelectContainer />
          </HStack>
        </Flex>
        <SingleSegmentContainer
          disabledChannels={[]}
          showRandomizerButton={currentUser.permissions.qualityAssurance}
          isRandomizerEnabled={isRandomizerEnabled}
          onToggleRandomizer={(state) => {
            if (state === 'on') {
              openRandomizer({
                numberOfConversations: numberOfRandomConversations,
                setEnableRandomizer: () => {
                  setSelectedConversationUid(undefined)
                  randomData.refetch() //to force refresh when use clicks the button again
                  setIsEnabledRandomizer(true)
                },
                setNumberOfConversations: (n: number) =>
                  setNumberOfRandomConversations(n),
              })
            } else if (state === 'off') {
              setIsEnabledRandomizer(false)
              setSelectedConversationUid(undefined)
            }
          }}
          filterDefinitions={filterDefinitions}
          onStateChange={handleSegmentStateChange}
        />
      </Box>
      <ErrorBoundary>
        <Grid
          templateColumns={{
            base: 'minmax(12.5rem, 19rem) 1px minmax(auto, 100%)',
          }}
          gap={2}
          flex="1 1 auto"
          overflowY="hidden"
        >
          <GridItem h="100%" overflowY="hidden">
            <ConversationsTable
              isRandomizerEnabled={isRandomizerEnabled}
              selectedConversationUid={selectedConversationUid}
              onConversationSelected={setSelectedConversationUid}
              randomConversations={randomData.data}
              randomConversationsIsLoading={randomData.isFetching}
              refetchRandomConversations={handleRefetchRandomConversations}
            />
          </GridItem>
          <GridItem>
            <Divider orientation="vertical" />
          </GridItem>
          <GridItem minW="52rem" w="100%" h="100%" overflowY="hidden">
            {selectedConversationUid ? (
              <ConversationDetails
                uid={selectedConversationUid}
                onTabOpen={handleConversationTabOpen}
                navigateToConversation={handleNavigateToConversation}
                isSidebarView={false}
              />
            ) : (
              <NoConversationFound />
            )}
          </GridItem>
        </Grid>
      </ErrorBoundary>
    </Flex>
  )
}

export default ConversationsPage
