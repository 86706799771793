import { BaseConversation, useConversations } from '@capturi/api-conversations'
import {
  Period,
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Text,
  VStack,
} from '@chakra-ui/react'
import React, {
  ReactNode,
  startTransition,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Link, useNavigate } from 'react-router-dom'

import analytics from '@capturi/analytics'
import { PhoneFilterValues } from '@capturi/api-filters'
import { useCurrentUser } from '@capturi/core'
import { useSingleUser } from '@capturi/stores'
import { Emoji } from '@capturi/ui-components'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { InfiniteData } from '@tanstack/react-query'
import UserAvatar from 'components/UserAvatar'
import isEqual from 'react-fast-compare'
import { MdArrowUpward } from 'react-icons/md'
import formatSeconds from 'utils/formatSeconds'
import routes from '../routes'
import IsReviewedCheckmark from './isReviewedCheckmark.svg'

const ConversationsTable: React.FC<{
  selectedConversationUid?: string
  onConversationSelected: (conversationUid: string | undefined) => void
  isRandomizerEnabled: boolean
  randomConversations: InfiniteData<BaseConversation[]> | undefined
  randomConversationsIsLoading: boolean
  refetchRandomConversations: () => void
}> = ({
  selectedConversationUid,
  onConversationSelected,
  isRandomizerEnabled,
  randomConversations,
  refetchRandomConversations,
  randomConversationsIsLoading,
}) => {
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement>(null)
  const currentUser = useCurrentUser()

  const segmentState = useFirstPhoneSegmentState()
  const filter = segmentState.getFilterRequestModel()
  const [prevSegmentStateValues, setPrevSegmentStateValues] = useState<
    PhoneFilterValues | undefined
  >(segmentState.values)

  const { period, periodDef } = useFilterPeriodContext()
  const [prevPeriod, setPrevPeriod] = useState<Period>(period)

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useConversations(filter)

  const conversations: InfiniteData<BaseConversation[]> | undefined =
    isRandomizerEnabled && randomConversations ? randomConversations : data

  const isEmpty = conversations?.pages?.[0]?.length === 0
  const handleLoadMoreClicked = useCallback((): void => {
    fetchNextPage()
    analytics.event('conversationList_loadMore')
  }, [fetchNextPage])

  const handleConversationSelected = useCallback(
    (uid: string): void => {
      if (uid) {
        startTransition(() => {
          navigate({
            pathname: routes.conversation(uid),
            search: new URLSearchParams({ period: periodDef.name }).toString(),
          })
        })
        onConversationSelected(uid)
      }
    },
    [navigate, onConversationSelected, periodDef.name],
  )

  const firstConversationUid = conversations?.pages[0][0]?.uid
  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  useEffect(() => {
    if (!firstConversationUid) return

    if (!selectedConversationUid) {
      // reselect first conversation if list loaded and nothing was selected before
      handleConversationSelected(firstConversationUid)
      if (ref.current) ref.current.scrollTop = 0
    } else if (segmentState.values !== prevSegmentStateValues) {
      setPrevSegmentStateValues(segmentState.values)
      handleConversationSelected(firstConversationUid)
    } else if (!isEqual(period, prevPeriod)) {
      setPrevPeriod(period)
      handleConversationSelected(firstConversationUid)
    }
  }, [
    handleConversationSelected,
    firstConversationUid,
    segmentState.values,
    prevSegmentStateValues,
    filter.values,
    period,
    prevPeriod,
    selectedConversationUid,
  ])

  if (error) {
    return (
      <Flex mt={2} direction="column" alignItems="center">
        <Emoji symbol="❤️‍🩹" fontSize="70px" />
        <Text fontSize="lg">
          <Trans>This should not happen</Trans>
        </Text>
        <Text fontSize="sm" color="textMuted" textAlign="center">
          {error.message}
        </Text>
      </Flex>
    )
  }
  if (isEmpty && currentUser.isTeamLead && filter.teamUids == null) {
    return (
      <Flex mt={2} direction="column" alignItems="center">
        <Icon as={MdArrowUpward} boxSize="24" />
        <Text fontSize="lg" textAlign="center">
          <Trans>You need to select a team in the filter above</Trans>
        </Text>
      </Flex>
    )
  }

  if (isEmpty) {
    return (
      <Flex mt={2} direction="column" alignItems="center">
        <Emoji symbol="👀" fontSize="70px" />
        <Text fontSize="lg">
          <Trans>No results</Trans>
        </Text>
        <Text fontSize="sm" color="textMuted" textAlign="center">
          <Trans>
            Try to increase the period up in the right corner or make your
            search terms broader
          </Trans>
        </Text>
      </Flex>
    )
  }

  return (
    <Box id="container" h="100%" position="relative">
      <Box h="100%" overflowY="auto" ref={ref} pr="2">
        <Box
          py={0}
          overflowX="hidden"
          border="1px solid"
          borderColor="gray.200"
        >
          {isLoading ? (
            <>
              <ConversationRowSkeleton />
              <ConversationRowSkeleton />
              <ConversationRowSkeleton />
              <ConversationRowSkeleton />
              <ConversationRowSkeleton />
              <ConversationRowSkeleton />
              <ConversationRowSkeleton />
            </>
          ) : (
            (conversations?.pages ?? []).map((d) =>
              d.map((c) => (
                <ConversationRow
                  isReviewed={c.qaIsReviewed}
                  key={c.uid}
                  conversationUid={c.uid}
                  customer={c.customer}
                  dateTime={c.dateTime}
                  duration={c.duration}
                  isSelected={c.uid === selectedConversationUid}
                  title={c.subject}
                  userUid={c.userUid}
                  onSelectConversation={handleConversationSelected}
                />
              )),
            )
          )}
          <Flex my={4} justify="center" w="100%">
            {isRandomizerEnabled ? (
              <Button
                onClick={refetchRandomConversations}
                isLoading={randomConversationsIsLoading}
              >
                <Trans>Load new Conversations</Trans>
              </Button>
            ) : (
              !(isLoading || isEmpty) && (
                <Button
                  onClick={handleLoadMoreClicked}
                  isDisabled={!hasNextPage || isFetchingNextPage}
                  isLoading={isFetchingNextPage}
                >
                  {!hasNextPage ? (
                    <Trans>No more conversations</Trans>
                  ) : (
                    <Trans>Load more</Trans>
                  )}
                </Button>
              )
            )}
          </Flex>
        </Box>
      </Box>
      {/* The top border */}
      <Box
        top={0}
        right={2}
        background="gray.200"
        position="absolute"
        h="1px"
        w="100%"
      />
    </Box>
  )
}

const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const ConversationRowListItem: React.FC<{
  isSelected?: boolean
  conversationUid?: string
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  children?: ReactNode
}> = ({ isSelected = false, onClick, conversationUid, children }) => (
  <Box
    background={isSelected ? 'white' : 'gray.50'}
    borderLeftColor={isSelected ? 'primary.500' : 'gray.200'}
    borderLeftWidth={isSelected ? 2 : 0}
    cursor="pointer"
    borderBottom="1px"
    borderBottomColor="gray.300"
    h={24}
    padding="2"
    position="relative"
    display="flex"
    justifyContent="flex-start"
    alignItems="center"
    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // If the user holds command on mac or ctrl on Windows
      // It means they want to open the conversation in a new tab
      // dSo we shouldn't navigate to the conversation in the current window
      if (e.metaKey || e.ctrlKey) {
        return
      }
      e.preventDefault()
      onClick?.(e)
    }}
    as={Link}
    to={routes.conversation(conversationUid || '')}
  >
    {children}
  </Box>
)

type ConversationRowProps = {
  conversationUid: string
  title: string
  dateTime: Date
  duration: number
  customer: string
  userUid: string
  isSelected: boolean
  isReviewed: boolean
  onSelectConversation: (
    uid: string,
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void
}

const ConversationRow = React.memo<ConversationRowProps>(
  function ConversationRow({
    conversationUid,
    title,
    dateTime,
    duration,
    customer,
    isSelected,
    onSelectConversation,
    userUid,
    isReviewed,
  }) {
    const { permissions } = useCurrentUser()
    const { name, profileImage } = useSingleUser(userUid)
    const handleSelectConversation = useCallback(
      (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        onSelectConversation(conversationUid, e)
      },
      [onSelectConversation, conversationUid],
    )
    return (
      <ConversationRowListItem
        conversationUid={conversationUid}
        isSelected={isSelected}
        onClick={handleSelectConversation}
      >
        <Flex
          alignItems="start"
          justifyContent="space-between"
          direction="column"
          color="gray.600"
          h="100%"
          w="100%"
        >
          <VStack alignItems="start" spacing={0} width="100%">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Text
                color={isSelected ? 'primary.500' : 'gray.800'}
                fontWeight={isSelected ? 'medium' : 'regular'}
                minH={5}
                noOfLines={2}
              >
                {title}
              </Text>
              {permissions.qualityAssurance && isReviewed && (
                <Icon boxSize="5" as={IsReviewedCheckmark} />
              )}
            </Flex>
            <HStack fontSize="sm" spacing={1}>
              <Text>{i18n.date(dateTime, dateFormat)}</Text>
              <Text>|</Text>
              <Text>{customer}</Text>
            </HStack>
          </VStack>
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <HStack>
              <UserAvatar name={name} profileImage={profileImage} size="xs" />
              <Text fontSize="xs" noOfLines={1}>
                {name}
              </Text>
            </HStack>

            <Text fontSize="xs">{formatSeconds(duration)}</Text>
          </Flex>
        </Flex>
      </ConversationRowListItem>
    )
  },
)

const ConversationRowSkeleton = React.memo(function ConversationRowSkeleton() {
  return (
    <ConversationRowListItem>
      <Flex
        alignItems="start"
        justifyContent="space-between"
        direction="column"
        color="gray.600"
        h="100%"
        w="100%"
      >
        <VStack alignItems="start" spacing={1} w="100%">
          <Skeleton borderRadius="md" h="4" w="80%">
            <SkeletonText mt="2" noOfLines={1} spacing="1" />
          </Skeleton>
          <Skeleton borderRadius="md" w="70%" h="3" />
        </VStack>
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <HStack>
            <SkeletonCircle w={6} h={6} />
            <Skeleton w={10} h="2" />
          </HStack>
          <Skeleton w={10} h="2" />
        </Flex>
      </Flex>
    </ConversationRowListItem>
  )
})

export default ConversationsTable
